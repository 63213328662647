<template>
    <v-snackbar v-model="notification.visible" :color="notification.type" :timeout="notification.timeout">
        {{ notification.message }}
        <v-btn text @click="close">Close</v-btn>
    </v-snackbar>
</template>

<script>
import { CLEAR_NOTIFICATION } from "@/store/mutations.type";

export default {
    name: "Notification",

    computed: {
        notification: {
            get() {
                return this.$store.state.notification.notification;
            },
            set(value) {
                this.$store.commit(CLEAR_NOTIFICATION, value);
            }
        }
    },

    methods: {
        close() {
            this.$store.commit(CLEAR_NOTIFICATION);
        }
    }
};
</script>
